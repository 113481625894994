/* Colors */
$main-purple: #4B1F89;
$secondary-purple: #331755;
$light-purple: #AA97C4;
$main-green: #5DBC68;
$main-blue: #00ffff;
$secondary-blue: #6FCBDC;
$light-blue: #5AC6D4;
$main-yellow: #F4BA18;
$label-grey: #3C4B53;
$placeholder-grey: #8E999F;
$dark-grey: #565E62;
$light-grey: #F9F7F7;

/* Breakpoints */
$mobile-sm-breakpoint: 375px;
$mobile-md-breakpoint: 540px;
$mobile-lg-breakpoint: 768px;
$tablet-breakpoint: 1024px;
$desktop-md-breakpoint: 1280px;
$desktop-lg-breakpoint: 1440px;
