@import './vars.scss';

/* Animations */
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

/* Structure */
.container {
    display: block;
    margin: 0 auto;
    padding: 0 16px;

    @media(min-width: $tablet-breakpoint) {
        max-width: 1024px;
    }

    @media(min-width: $desktop-md-breakpoint) {
        max-width: 1024px;
    }

    @media(min-width: $desktop-lg-breakpoint) {
        padding: 0;
        max-width: 1200px;
    }
}

span {
    display: inline-block;
}

h1, h2, h3, h4, h5, h6, p, a, button, label, input::placeholder {
    margin: 0;
    font-family: 'WorkSans', sans-serif;
}

h1, h2, h3, h4, h5, h6, p, a {
    text-shadow: 0 1px #d1d1d1;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Forms */
.form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &--input {
        display: flex;
        flex-direction: column;
        text-align: start;
        margin-bottom: 16px;

        &.half {
            width: 47%;
        }

        &.full {
            width: 100%;
        }

        label {
            font-size: 0.875rem;
            line-height: 1.2;
            color: $label-grey;

            @media(min-width: $desktop-lg-breakpoint) {
                font-size: 1rem;
            }
        }

        input, textarea, select {
            margin-top: 8px;
            padding: 16px;

            border: 1px solid $light-purple;
            border-radius: 8px;
            
            font-size: 0.875rem;
            line-height: 1.2;
            color: $label-grey;

            @media(min-width: $desktop-lg-breakpoint) {
                font-size: 1rem;
            }

            &::placeholder {
                font-size: 0.875rem;
                line-height: 1.2;
                color: $placeholder-grey;
                font-style: italic;
                letter-spacing: 0;

                @media(min-width: $desktop-lg-breakpoint) {
                    font-size: 1rem;
                }
            }
        }

        textarea {
            min-height: 150px;
            resize: vertical;
        }
    }

    &--button-container {
        width: 100%;

        svg {
            width: 32px;
            height: 32px;

            margin-right: 32px;
        }
    }
}

/* Spinner */
.spinner {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

/* Buttons */
.btn {
    padding: 12px 20px;
    border-radius: 8px;
    border-width: 1px;
    color: white;
    font-family: 'WorkSans', sans-serif;
    font-size: 0.875rem;
    line-height: 1.2;
    border-color: rgb(0, 255, 255);
    background-color: rgb(75, 31, 137);

    cursor: pointer;
    transition: .3s ease-in-out;

    &:hover {
        opacity: 0.8;
        transform: scale(1.05);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
        transform: scale(1);
    }
}

/* Texts */
h1 {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 300;
    color: $main-purple;

    @media (min-width: $tablet-breakpoint) {
        font-size: 2.5rem;
    }

    @media (min-width: $desktop-lg-breakpoint) {
        font-size: 3.5rem;
    }
}

h2 {
    font-size: 1.75rem;
    line-height: 1.2;
    font-weight: 500;
    color: $main-purple;

    @media (min-width: $tablet-breakpoint) {
        font-size: 2rem;
    }

    @media (min-width: $desktop-lg-breakpoint) {
        font-size: 3rem;
    }
}

h3 {
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: 500;
    color: $main-purple;

    @media (min-width: $tablet-breakpoint) {
        font-size: 1.75rem;
    }

    @media (min-width: $desktop-lg-breakpoint) {
        font-size: 2.5rem;
    }
}

h4 {
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: 500;
    color: $main-purple;

    @media (min-width: $tablet-breakpoint) {
        font-size: 1.5rem;
    }

    @media (min-width: $desktop-lg-breakpoint) {
        font-size: 2rem;
    }
}

h5 {
    font-size: 1.125rem;
    line-height: 1.2;
    font-weight: 500;
    color: $main-purple;

    @media (min-width: $tablet-breakpoint) {
        font-size: 1.25rem;
    }

    @media (min-width: $desktop-lg-breakpoint) {
        font-size: 1.5rem;
    }
}

h5 {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 500;
    color: $main-purple;

    @media (min-width: $tablet-breakpoint) {
        font-size: 1.125rem;
    }

    @media (min-width: $desktop-lg-breakpoint) {
        font-size: 1.25rem;
    }
}

h6 {
    font-size: 0.875rem;
    line-height: 1.2;
    font-weight: 700;
    color: $main-purple;

    @media (min-width: $tablet-breakpoint) {
        font-size: 1rem;
    }

    @media (min-width: $desktop-lg-breakpoint) {
        font-size: 1.125rem;
    }
}

p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    color: $dark-grey;

    @media (min-width: $desktop-lg-breakpoint) {
        font-size: 1.25rem;
    }
}

a {
    text-decoration: none;
    color: $main-purple;
}

.highlighted-text {
    &--word {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            bottom: -6px;
            left: 0px;

            height: 5px;
            width: calc(100% + 16px);

            background-color: var(--hightlight-color);
            transform: skew(-20deg);

            @media (min-width: $tablet-breakpoint) {
                bottom: -7px;
                height: 6px;
            }
        }
    }
}